import React from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { IconContext } from 'react-icons'

const iconSize = 25

interface ShareButtons {
  title: string
  url: string
}

const ShareButtons = ({ title, url }: ShareButtons): JSX.Element => {
  return (
    <div className="d-flex justify-content-end">
      <IconContext.Provider value={{ color: '4267B2', className: 'global-class-name' }}>
        <span className="mr-3">
          <FacebookShareButton url={url} quote={title}>
            <FaFacebookSquare size={iconSize} />
          </FacebookShareButton>
        </span>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: '#2867B2', className: 'global-class-name' }}>
        <span className="mr-3">
          <LinkedinShareButton url={url} source={url} title={title} summary={title}>
            <FaLinkedin size={iconSize} />
          </LinkedinShareButton>
        </span>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: '#1DA1F2', className: 'global-class-name' }}>
        <span>
          <TwitterShareButton url={url} title={title} via="Ingenieuses_ets">
            <FaTwitter size={iconSize} />
          </TwitterShareButton>
        </span>
      </IconContext.Provider>
    </div>
  )
}

export default ShareButtons
