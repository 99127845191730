import React from 'react'
import { navigate } from 'gatsby'
import { Container, UncontrolledCarousel, Button, CardImg } from 'reactstrap'
import { BlogEntry, Location } from 'shared/types'
import { baseUrl } from 'shared/constants'
import { BLOG_LIST } from 'shared/routes'
import ShareButtons from 'components/ShareButtons/index'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'

interface BlogPost {
  location: Location
  blogPost: BlogEntry
}

const BlogPost = ({ blogPost, location }: BlogPost): JSX.Element => {
  const language = useIntl().locale || 'fr'
  const urlWithLocale = language === 'en' ? `/${language}${BLOG_LIST}` : `${BLOG_LIST}`
  return (
    <div className="main">
      <div className="section text-center top-of-page">
        <Container className="title article">
          <div className="ml-auto mr-auto col-md-12">
            <h1 className="title">{blogPost?.title}</h1>
            <h3 className="title-uppercase">
              <small>
                <FormattedMessage id="blog_by" /> {blogPost?.author}
              </small>
            </h3>
            <h6 className="title-uppercase">{blogPost?.date}</h6>
            <ShareButtons title={blogPost?.title} url={`${baseUrl}/${location.pathname}`} />
            {blogPost?.imageUrls &&
              (blogPost?.imageUrls.length > 1 ? (
                <UncontrolledCarousel
                  className="img-blog-article"
                  items={blogPost.imageUrls.map((imgUrl) => ({
                    src: imgUrl,
                    altText: '',
                    caption: '',
                  }))}
                />
              ) : (
                <CardImg className="img-blog-article mt-2" src={blogPost?.imageUrls[0]} alt="" />
              ))}
            <div className="article-content">
              {blogPost?.text}
              {blogPost?.references && (
                <>
                  <hr />
                  <div className="references-section">{blogPost?.references}</div>
                </>
              )}
            </div>
            <ShareButtons title={blogPost?.title} url={`${baseUrl}/${location.pathname}`} />
            <Button
              color="primary"
              onClick={() => {
                navigate(urlWithLocale)
              }}
              className="return-btn"
            >
              <FormattedMessage id="blog_btn_return" />
            </Button>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default BlogPost
