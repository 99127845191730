import React from 'react'
import { graphql } from 'gatsby'
import BlogPost from 'components/BlogPage/BlogPost'
import Header from 'components/Header'
import Footer from 'components/Footer'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import { mapToBlogEntry } from 'services'
import { ContentfulBlogPost, Location } from 'shared/types'
import { genericTitle, baseUrl } from 'shared/constants'
import { BLOG_LIST } from 'shared/routes'
import { convertToSlug } from 'utils/functions'

interface Blog {
  location: Location
  data: ContentfulBlogPost
}

const BlogEntry = ({ location, data }: Blog): JSX.Element => {
  const blogPost = mapToBlogEntry(data)
  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={blogPost.title}
        url={`${baseUrl}${BLOG_LIST}/${convertToSlug(blogPost.title)}`}
        image={blogPost.imageUrls[0]}
      />
      <ScrollToTop location={location} />
      <Header location={location} />
      <BlogPost blogPost={blogPost} location={location} />
      <Footer />
    </>
  )
}

export default BlogEntry

export const query = graphql`
  query BlogPost($id: String!, $locale: String) {
    contentfulBlog(contentful_id: { eq: $id }, node_locale: { eq: $locale }) {
      blogImages {
        fixed(width: 1500) {
          src
        }
      }
      blogText {
        raw
      }
      blogReferences {
        raw
      }
      blogTitle
      blogAuthor
      contentful_id
      blogPublishDate
    }
  }
`
